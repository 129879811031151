<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getContactError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('contacts:contactInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getContactError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('contacts:contactDetails')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'contacts:contactInformation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            data-cy-contacts="fullName"
                                            v-model="getContactModal.name"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .name
                                            "
                                            :label="`${$t(
                                                'contacts:fullName'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="shortName"
                                            v-model="getContactModal.shortName"
                                            :label="$t('contacts:shortName')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            data-cy-contacts="contactID"
                                            v-model="getContactModal.contactId"
                                            :label="$t('contacts:contactID')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="5">
                                        <v-text-field
                                            data-cy-contacts="TIN"
                                            v-model="getContactModal.NIP"
                                            :label="$t('contacts:nip')"
                                            v-mask="nipMask"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .NIP
                                            "
                                            @change="
                                                checkIsNipAvailableChange()
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'contacts:specificInformation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            data-cy-contacts="country"
                                            v-model="getContactModal.state"
                                            :label="$t('contacts:state')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            data-cy-contacts="city"
                                            v-model="getContactModal.city"
                                            :label="`${$t('contacts:city')}*`"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .city
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            data-cy-contacts="postCode"
                                            v-model="getContactModal.postCode"
                                            :label="`${$t(
                                                'contacts:postCode'
                                            )}*`"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .postCode
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            data-cy-contacts="postAddress"
                                            v-model="getContactModal.post"
                                            :label="$t('contacts:post')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="street"
                                            v-model="getContactModal.street"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .street
                                            "
                                            :label="`${$t('contacts:street')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="houseNumber"
                                            v-model="
                                                getContactModal.houseNumber
                                            "
                                            :label="`${$t(
                                                'contacts:houseNumber'
                                            )}*`"
                                            :error-messages="
                                                getContactError.stepOne.fields
                                                    .houseNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="apartmentNumber"
                                            v-model="
                                                getContactModal.apartmentNumber
                                            "
                                            :label="
                                                $t('contacts:apartmentNumber')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-1">
                                        <Separator
                                            data-cy-contacts="contactDetails"
                                            :text="
                                                $t('contacts:contactDetails')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="phoneNumber"
                                            v-model="
                                                getContactModal.phoneNumber
                                            "
                                            type="number"
                                            :label="$t('contacts:phoneNumber')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="email"
                                            v-model="getContactModal.email"
                                            :error-messages="
                                                getContactError.stepTwo.fields
                                                    .email
                                            "
                                            :label="$t('contacts:email')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            data-cy-contacts="website"
                                            v-model="getContactModal.website"
                                            :label="$t('contacts:website')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            data-cy-contacts="contactType"
                                            v-model="
                                                getContactModal.contactType
                                            "
                                            :items="
                                                getRegistryDictionaries.contactType
                                                    ? getRegistryDictionaries.contactType
                                                    : []
                                            "
                                            :label="$t('contacts:contactType')"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mxs-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            data-cy-contacts="accountManager"
                                            v-model="
                                                getContactModal.clientGuardian
                                            "
                                            :label="
                                                $t('contacts:clientGuardian')
                                            "
                                            :items="getUsersList"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    selection.item.lastname
                                                }}</span>
                                            </template>
                                            <template v-slot:item="item">
                                                <span>{{
                                                    item.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    item.item.lastname
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { nipMask } from '../../../../validators/formValidation'

export default {
    i18nOptions: { namespaces: ['contacts'] },
    computed: {
        ...mapGetters([
            'getContactModal',
            'getContactError',
            'getUsersList',
            'getRegistryDictionaries',
        ]),
    },
    data() {
        return {
            e1: 1,
            editable: true,
            contactType: [
                { text: this.$t('contacts:all'), value: 'all' },
                { text: this.$t('contacts:contractor'), value: 'contractor' },
                { text: this.$t('contacts:client'), value: 'client' },
            ],
            nipMask: nipMask,
        }
    },
    methods: {
        ...mapActions(['checkIsNipAvailable']),
        async checkIsNipAvailableChange() {
            const nip = this.getContactModal.NIP
            if (nip) {
                const resp = await this.checkIsNipAvailable({
                    nip: nip,
                })
                if (!resp) {
                    this.getContactError.stepOne.fields.NIP =
                        'Kontrahent z takim numerem NIP już istnieje.'

                    this.$forceUpdate()
                } else {
                    this.getContactError.stepOne.fields.NIP = ''

                    this.$forceUpdate()
                }
            }
        },
    },

    watch: {
        // whenever question changes, this function will run
        'getContactModal.street'(v) {
            if (v) {
            
                if (v.startsWith('ul.') || v.startsWith('Ul.')) {
                    this.getContactError.stepOne.fields.street =
                        'Podaj adres bez ul.'
                    this.getContactError.stepOne.validation = false
                } else {
                    this.getContactError.stepOne.fields.street = ''
                    this.getContactError.stepOne.validation = true
                }
            }
        },
    },
}
</script>
