var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getContactError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('contacts:contactInformation')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getContactError.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('contacts:contactDetails')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'contacts:contactInformation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"fullName","error-messages":_vm.getContactError.stepOne.fields
                                                .name,"label":`${_vm.$t(
                                            'contacts:fullName'
                                        )}*`},model:{value:(_vm.getContactModal.name),callback:function ($$v) {_vm.$set(_vm.getContactModal, "name", $$v)},expression:"getContactModal.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"shortName","label":_vm.$t('contacts:shortName')},model:{value:(_vm.getContactModal.shortName),callback:function ($$v) {_vm.$set(_vm.getContactModal, "shortName", $$v)},expression:"getContactModal.shortName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"contactID","label":_vm.$t('contacts:contactID')},model:{value:(_vm.getContactModal.contactId),callback:function ($$v) {_vm.$set(_vm.getContactModal, "contactId", $$v)},expression:"getContactModal.contactId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.nipMask),expression:"nipMask"}],attrs:{"data-cy-contacts":"TIN","label":_vm.$t('contacts:nip'),"error-messages":_vm.getContactError.stepOne.fields
                                                .NIP},on:{"change":function($event){return _vm.checkIsNipAvailableChange()}},model:{value:(_vm.getContactModal.NIP),callback:function ($$v) {_vm.$set(_vm.getContactModal, "NIP", $$v)},expression:"getContactModal.NIP"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'contacts:specificInformation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"country","label":_vm.$t('contacts:state')},model:{value:(_vm.getContactModal.state),callback:function ($$v) {_vm.$set(_vm.getContactModal, "state", $$v)},expression:"getContactModal.state"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"city","label":`${_vm.$t('contacts:city')}*`,"error-messages":_vm.getContactError.stepOne.fields
                                                .city},model:{value:(_vm.getContactModal.city),callback:function ($$v) {_vm.$set(_vm.getContactModal, "city", $$v)},expression:"getContactModal.city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"postCode","label":`${_vm.$t(
                                            'contacts:postCode'
                                        )}*`,"error-messages":_vm.getContactError.stepOne.fields
                                                .postCode},model:{value:(_vm.getContactModal.postCode),callback:function ($$v) {_vm.$set(_vm.getContactModal, "postCode", $$v)},expression:"getContactModal.postCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"postAddress","label":_vm.$t('contacts:post')},model:{value:(_vm.getContactModal.post),callback:function ($$v) {_vm.$set(_vm.getContactModal, "post", $$v)},expression:"getContactModal.post"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"street","error-messages":_vm.getContactError.stepOne.fields
                                                .street,"label":`${_vm.$t('contacts:street')}*`},model:{value:(_vm.getContactModal.street),callback:function ($$v) {_vm.$set(_vm.getContactModal, "street", $$v)},expression:"getContactModal.street"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"houseNumber","label":`${_vm.$t(
                                            'contacts:houseNumber'
                                        )}*`,"error-messages":_vm.getContactError.stepOne.fields
                                                .houseNumber},model:{value:(
                                            _vm.getContactModal.houseNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getContactModal, "houseNumber", $$v)},expression:"\n                                            getContactModal.houseNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"apartmentNumber","label":_vm.$t('contacts:apartmentNumber')},model:{value:(
                                            _vm.getContactModal.apartmentNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getContactModal, "apartmentNumber", $$v)},expression:"\n                                            getContactModal.apartmentNumber\n                                        "}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"data-cy-contacts":"contactDetails","text":_vm.$t('contacts:contactDetails')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"phoneNumber","type":"number","label":_vm.$t('contacts:phoneNumber')},model:{value:(
                                            _vm.getContactModal.phoneNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getContactModal, "phoneNumber", $$v)},expression:"\n                                            getContactModal.phoneNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"email","error-messages":_vm.getContactError.stepTwo.fields
                                                .email,"label":_vm.$t('contacts:email')},model:{value:(_vm.getContactModal.email),callback:function ($$v) {_vm.$set(_vm.getContactModal, "email", $$v)},expression:"getContactModal.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-contacts":"website","label":_vm.$t('contacts:website')},model:{value:(_vm.getContactModal.website),callback:function ($$v) {_vm.$set(_vm.getContactModal, "website", $$v)},expression:"getContactModal.website"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"data-cy-contacts":"contactType","items":_vm.getRegistryDictionaries.contactType
                                                ? _vm.getRegistryDictionaries.contactType
                                                : [],"label":_vm.$t('contacts:contactType'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mxs-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getContactModal.contactType
                                        ),callback:function ($$v) {_vm.$set(_vm.getContactModal, "contactType", $$v)},expression:"\n                                            getContactModal.contactType\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"data-cy-contacts":"accountManager","label":_vm.$t('contacts:clientGuardian'),"items":_vm.getUsersList,"item-text":"name","item-value":"_id","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))])]}},{key:"item",fn:function(item){return [_c('span',[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.item.lastname))])]}}]),model:{value:(
                                            _vm.getContactModal.clientGuardian
                                        ),callback:function ($$v) {_vm.$set(_vm.getContactModal, "clientGuardian", $$v)},expression:"\n                                            getContactModal.clientGuardian\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }