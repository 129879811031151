<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getBranchError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:information') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <Separator :text="'Dane oddziału'" />
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            data-cy-branches="name"
                                            :label="`${$t(
                                                'contacts:branchName'
                                            )}*`"
                                            v-model="getBrancheModal.name"
                                            :error-messages="
                                                getBranchError.stepOne.name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            data-cy-branches="city"
                                            :label="`${$t('contacts:city')}*`"
                                            v-model="getBrancheModal.city"
                                            :error-messages="
                                                getBranchError.stepOne.city
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            data-cy-branches="postCode"
                                            :label="`${$t(
                                                'contacts:postCode'
                                            )}*`"
                                            v-model="getBrancheModal.postCode"
                                            :error-messages="
                                                getBranchError.stepOne.postCode
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            data-cy-branches="address"
                                            :label="`${$t(
                                                'contacts:address'
                                            )}*`"
                                            v-model="getBrancheModal.address"
                                            :error-messages="
                                                getBranchError.stepOne.address
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <Separator :text="'Dane dodatkowe'" />
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            data-cy-branches="phoneNumber"
                                            :label="$t('contacts:phoneNumber')"
                                            v-model="
                                                getBrancheModal.phoneNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            data-cy-branches="email"
                                            :label="$t('contacts:email')"
                                            v-model="getBrancheModal.email"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-if="
                                        action == GlobalActions.update &&
                                        isDelete()
                                    "
                                >
                                    <v-col>
                                        <v-checkbox
                                            class="my-0 mx-3"
                                            v-model="getBrancheModal.isDisabled"
                                            :label="$t('contacts:isDisabled')"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
    GlobalPermissions,
    GlobalActions,
} from '../../../../../../plugins/constsTypes'
export default {
    i18nOptions: { namespaces: ['global', 'contacts'] },
    data() {
        return {
            step: 1,
            GlobalActions,
        }
    },
    props: ['action'],
    watch: {
        // whenever question changes, this function will run
        'getBrancheModal.address'(v) {
            if (v) {
                if (v.startsWith('ul.') || v.startsWith('Ul.')) {
                    this.getBranchError.stepOne.address = 'Podaj adres bez ul.'
                    this.getBranchError.stepOne.validation = false
                } else {
                    this.getBranchError.stepOne.address = ''
                    this.getBranchError.stepOne.validation = true
                }
                
            }
        },
    },
    computed: {
        ...mapGetters(['getBrancheModal', 'getBranchError']),
    },
    methods: {
        ...mapMutations['clearBranchErrors'],
        isDelete() {
            return this.checkPermissions(GlobalPermissions.delete)
        },
    },
}
</script>
