<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRepresentativeError.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('contacts:representativeInformations')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0 pb-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'contacts:representativeInformations'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <v-row
                                            ><v-col cols="6">
                                                <v-text-field
                                                    data-cy-representatives="firstName"
                                                    v-model="
                                                        getRepresentativeModal.name
                                                    "
                                                    :error-messages="
                                                        getRepresentativeError
                                                            .stepOne.name
                                                    "
                                                    :label="`${$t(
                                                        'contacts:name'
                                                    )}*`"
                                                ></v-text-field></v-col
                                            ><v-col cols="6"
                                                ><v-text-field
                                                    data-cy-representatives="lastName"
                                                    v-model="
                                                        getRepresentativeModal.lastname
                                                    "
                                                    :error-messages="
                                                        getRepresentativeError
                                                            .stepOne.lastname
                                                    "
                                                    :label="`${$t(
                                                        'contacts:lastname'
                                                    )}*`"
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                        <v-row
                                            ><v-col cols="6">
                                                <v-text-field
                                                    data-cy-representatives="position"
                                                    v-model="
                                                        getRepresentativeModal.position
                                                    "
                                                    :label="`${$t(
                                                        'contacts:position'
                                                    )}`"
                                                ></v-text-field></v-col
                                            ><v-col cols="6"
                                                ><v-text-field
                                                    data-cy-representatives="phoneNumber"
                                                    v-model="
                                                        getRepresentativeModal.phoneNumber
                                                    "
                                                    :label="`${$t(
                                                        'contacts:phoneNumber'
                                                    )}`"
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    data-cy-representatives="email"
                                                    v-model="
                                                        getRepresentativeModal.email
                                                    "
                                                    :label="`${$t(
                                                        'contacts:email'
                                                    )}`"
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu
                                                    v-model="data1"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            data-cy-representatives="birthday"
                                                            v-model="
                                                                getRepresentativeModal.dayOfBirth
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getRepresentativeModal.dayOfBirth = null
                                                            "
                                                            :label="`${$t(
                                                                'contacts:birthday'
                                                            )}`"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getRepresentativeModal.dayOfBirth
                                                        "
                                                        @input="data1 = false"
                                                    ></v-date-picker> </v-menu
                                            ></v-col>
                                            <v-col cols="6">
                                                <v-menu
                                                    v-model="data2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            data-cy-representatives="nameDay"
                                                            clearable
                                                            @click:clear="
                                                                getRepresentativeModal.dayOfName = null
                                                            "
                                                            v-model="
                                                                getRepresentativeModal.dayOfName
                                                            "
                                                            :label="`${$t(
                                                                'contacts:nameDay'
                                                            )}`"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getRepresentativeModal.dayOfName
                                                        "
                                                        @input="data2 = false"
                                                    ></v-date-picker> </v-menu
                                            ></v-col>
                                        </v-row>
                                        <v-row
                                            ><v-col cols="12">
                                                <v-textarea
                                                    data-cy-representatives="comments"
                                                    v-model="
                                                        getRepresentativeModal.comments
                                                    "
                                                    no-resize
                                                    rows="1"
                                                    :label="`${$t(
                                                        'contacts:comments'
                                                    )}`"
                                                ></v-textarea></v-col
                                        ></v-row>
                                        <v-row v-if="action == 'edit' && isDelete()">
                                            <v-col>
                                                <v-checkbox
                                                    class="my-0 mx-3"
                                                    v-model="
                                                        getRepresentativeModal.isDisabled
                                                    "
                                                    :label="
                                                        $t(
                                                            'contacts:isDisabled'
                                                        )
                                                    "
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { GlobalPermissions } from '../../../../../../plugins/constsTypes'
export default {
    i18nOptions: { namespaces: ['contacts'] },
    data() {
        return {
            e1: 1,
            editable: true,
            data1: '',
            data2: '',
        }
    },
    props:['action'],
    computed: {
        ...mapGetters(['getRepresentativeModal', 'getRepresentativeError']),
    },
    methods:{
        isDelete() {
            return this.checkPermissions(GlobalPermissions.delete);
        },
    }
}
</script>
