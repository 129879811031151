var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getBranchError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:information')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('Separator',{attrs:{"text":'Dane oddziału'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"data-cy-branches":"name","label":`${_vm.$t(
                                            'contacts:branchName'
                                        )}*`,"error-messages":_vm.getBranchError.stepOne.name},model:{value:(_vm.getBrancheModal.name),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "name", $$v)},expression:"getBrancheModal.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"data-cy-branches":"city","label":`${_vm.$t('contacts:city')}*`,"error-messages":_vm.getBranchError.stepOne.city},model:{value:(_vm.getBrancheModal.city),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "city", $$v)},expression:"getBrancheModal.city"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"data-cy-branches":"postCode","label":`${_vm.$t(
                                            'contacts:postCode'
                                        )}*`,"error-messages":_vm.getBranchError.stepOne.postCode},model:{value:(_vm.getBrancheModal.postCode),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "postCode", $$v)},expression:"getBrancheModal.postCode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"data-cy-branches":"address","label":`${_vm.$t(
                                            'contacts:address'
                                        )}*`,"error-messages":_vm.getBranchError.stepOne.address},model:{value:(_vm.getBrancheModal.address),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "address", $$v)},expression:"getBrancheModal.address"}})],1)],1),_c('Separator',{attrs:{"text":'Dane dodatkowe'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"data-cy-branches":"phoneNumber","label":_vm.$t('contacts:phoneNumber')},model:{value:(
                                            _vm.getBrancheModal.phoneNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "phoneNumber", $$v)},expression:"\n                                            getBrancheModal.phoneNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"data-cy-branches":"email","label":_vm.$t('contacts:email')},model:{value:(_vm.getBrancheModal.email),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "email", $$v)},expression:"getBrancheModal.email"}})],1)],1),(
                                    _vm.action == _vm.GlobalActions.update &&
                                    _vm.isDelete()
                                )?_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"my-0 mx-3",attrs:{"label":_vm.$t('contacts:isDisabled'),"hide-details":""},model:{value:(_vm.getBrancheModal.isDisabled),callback:function ($$v) {_vm.$set(_vm.getBrancheModal, "isDisabled", $$v)},expression:"getBrancheModal.isDisabled"}})],1)],1):_vm._e()],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }