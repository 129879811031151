<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: String,
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createContact', 'updateContact', 'fetchBranchesTable']),
        async save() {
            this.disabled = true
            let success = await this.createContact()
            if (success) this.$emit('closeModal')
            this.disabled = false
        },

        async edit() {
            this.disabled = true
            let success = await this.updateContact({
                id: this.$route.params.id,
            })
            this.fetchBranchesTable({ id: this.$route.params.id })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
